// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #008acf; //#3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #F9F9F9;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #32db64;
  --ion-color-secondary-rgb: 50,219,100;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #2cc158;
  --ion-color-secondary-tint: #47df74;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #009245; //#2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #F9F9F9;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #F9F9F9;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #b31420;
  --ion-color-danger-rgb: 179,20,32;
  --ion-color-danger-contrast: #F9F9F9;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #9e121c;
  --ion-color-danger-tint: #bb2c36;

  /** dark **/
  --ion-color-dark: #3d4a50; //#222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #F9F9F9;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #F9F9F9;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f4f4;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d7d7;
  --ion-color-light-tint: #f5f5f5;

  /** red **/
  --ion-color-red: #EE0000;
  --ion-color-red-rgb: 238,0,0;
  --ion-color-red-contrast: #E9F9F9;
  --ion-color-red-contrast-rgb: 255,255,255;
  --ion-color-red-shade: #d10000;
  --ion-color-red-tint: #f01a1a;

  /** orange **/
  --ion-color-orange: #DD7321;
  --ion-color-orange-rgb: 221,115,33;
  --ion-color-orange-contrast: #FFFFFF;
  --ion-color-orange-contrast-rgb: 0,0,0;
  --ion-color-orange-shade: #c2651d;
  --ion-color-orange-tint: #e08137;

  /** green **/
  --ion-color-green: #009245;
  --ion-color-green-rgb: 0,146,69;
  --ion-color-green-contrast: #F9E9F9;
  --ion-color-green-contrast-rgb: 255,255,255;
  --ion-color-green-shade: #00803d;
  --ion-color-green-tint: #1a9d58;

  /** lightgreen **/
  --ion-color-lightgreen: #57B235;
  --ion-color-lightgreen-rgb: 87,178,53;
  --ion-color-lightgreen-contrast: #FFFFFF;
  --ion-color-lightgreen-contrast-rgb: 0,0,0;
  --ion-color-lightgreen-shade: #4d9d2f;
  --ion-color-lightgreen-tint: #68ba49;

  /** lightblue **/
  --ion-color-lightblue: #20aaEf;
  --ion-color-lightblue-rgb: 32,170,239;
  --ion-color-lightblue-contrast: #F9F9E9;
  --ion-color-lightblue-contrast-rgb: 0,0,0;
  --ion-color-lightblue-shade: #1c96d2;
  --ion-color-lightblue-tint: #36b3f1;

  // secondary background
  --ion-color-background: #4d5c62;
}

/*
 * Shared Variables
 * ----------------------------------------------------------------------------
 */


//// Named Color Variables
//// --------------------------------------------------
//// Named colors makes it easy to reuse colors on various components.
//// It's highly recommended to change the default colors
//// to match your app's branding. Ionic uses a Sass map of
//// colors so you can add, rename and remove colors as needed.
//// The "primary" color is the only required color in the map.
//
//$colors: (
//  //primary:    #008acf,
//  //secondary:  #32db64,//
//  //danger:     #a30410,//
//  //green:      #009245,
//  light:      #f4f4f4,//
//  dark:       #222,//
//  //orange:     #DD7321,
//  //red:        #EE0000,
//  //lightgreen: #57B235,
//  //lightblue:  #20aaEf
//);

//.ion-color-primary {
//  --ion-color-base: var(--ion-color-primary);
//  --ion-color-base-rgb: var(--ion-color-primary-rgb);
//  --ion-color-contrast: var(--ion-color-primary-contrast);
//  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
//  --ion-color-shade: var(--ion-color-primary-shade);
//  --ion-color-tint: var(--ion-color-primary-tint);
//}
//
//.ion-color-secondary {
//  --ion-color-base: var(--ion-color-secondary);
//  --ion-color-base-rgb: var(--ion-color-secondary-rgb);
//  --ion-color-contrast: var(--ion-color-secondary-contrast);
//  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
//  --ion-color-shade: var(--ion-color-secondary-shade);
//  --ion-color-tint: var(--ion-color-secondary-tint);
//}
//
//.ion-color-success {
//  --ion-color-base: var(--ion-color-success);
//  --ion-color-base-rgb: var(--ion-color-success-rgb);
//  --ion-color-contrast: var(--ion-color-success-contrast);
//  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
//  --ion-color-shade: var(--ion-color-success-shade);
//  --ion-color-tint: var(--ion-color-success-tint);
//}
//
//.ion-color-danger {
//  --ion-color-base: var(--ion-color-danger);
//  --ion-color-base-rgb: var(--ion-color-danger-rgb);
//  --ion-color-contrast: var(--ion-color-danger-contrast);
//  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
//  --ion-color-shade: var(--ion-color-danger-shade);
//  --ion-color-tint: var(--ion-color-danger-tint);
//}
//
//.ion-color-light {
//  --ion-color-base: var(--ion-color-light);
//  --ion-color-base-rgb: var(--ion-color-light-rgb);
//  --ion-color-contrast: var(--ion-color-light-contrast);
//  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb);
//  --ion-color-shade: var(--ion-color-light-shade);
//  --ion-color-tint: var(--ion-color-light-tint);
//}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
  --ion-color-base-rgb: var(--ion-color-red-rgb);
  --ion-color-contrast: var(--ion-color-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-shade);
  --ion-color-tint: var(--ion-color-red-tint);
}

.ion-color-lightgreen {
  --ion-color-base: var(--ion-color-lightgreen);
  --ion-color-base-rgb: var(--ion-color-lightgreen-rgb);
  --ion-color-contrast: var(--ion-color-lightgreen-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightgreen-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightgreen-shade);
  --ion-color-tint: var(--ion-color-lightgreen-tint);
}

.ion-color-lightblue {
  --ion-color-base: var(--ion-color-lightblue);
  --ion-color-base-rgb: var(--ion-color-lightblue-rgb);
  --ion-color-contrast: var(--ion-color-lightblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightblue-shade);
  --ion-color-tint: var(--ion-color-lightblue-tint);
}




/*
@media (prefers-color-scheme: dark) {

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }


  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
*/
