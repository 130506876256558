// http://ionicframework.com/docs/theming/


// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.
:root {

  /*.dummy {
    border: 2px solid orange;
  }*/

  .scroll-content {
    overflow-y: auto;
  }

  $screen-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
  ) !default;

  --ion-grid-column-padding: 9px;
  //--ion-grid-column-padding-xl: 9px;
  //--ion-grid-column-padding-lg: 9px;
  --ion-grid-column-padding-md: 7px;
  --ion-grid-column-padding-sm: 4px;
  --ion-grid-column-padding-xs: 4px;

  $breakpoints-keys: map-keys($screen-breakpoints);
  $breakpoints-values: map-values($screen-breakpoints);
  @for $i from 1 to length($breakpoints-keys)+1 {
    @if $i < length($breakpoints-keys) {
      .hidden-#{nth($breakpoints-keys, $i)} {
        @media (min-width: nth($breakpoints-values, $i)) and (max-width: #{nth($breakpoints-values, $i+1)}) {
          display: none;
        }
      }
    } @else {
      .hidden-#{nth($breakpoints-keys, $i)} {
        @media (min-width: nth($breakpoints-values, $i)) {
          display: none;
        }
      }
    }
  }
  @each $size, $size-value in $screen-breakpoints {
    .hide-#{$size} {
      @media (min-width: $size-value) {
        display: none;
      }
    }
  }

  ion-header {
    -webkit-box-shadow: 0px 10px 31px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 31px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 31px 1px rgba(0, 0, 0, 0.1);
  }

  ion-menu-button {
    --background: var(--ion-color-primary);
    --border-radius: 0;
    min-width: 60px;
    min-height: 48px;
  }
  ion-button {
    --border-radius: 0px !important;
  }
  ion-toolbar {
    --padding-top: 0px;
    --padding-bottom: 0px;
    --padding-start: 0px;
    --padding-end: 0px;
    --min-height: 48px;
    ion-button {
      height: 48px;
      min-height: 48px;
      min-width: 48px;
      margin: 0px;
      letter-spacing: normal !important;
    }
  }

  .toolbar-background-md, .toolbar-background-ios {
    border-color: #b2b2b2 !important;
    background: #3d4a50 !important;
    color: #dbe0e4 !important;
  }

  .bar-button-default-md, .bar-button-clear-md-default, .bar-button-md-default {
    color: #dbe0e4;
  }

  .bar-button-default-ios, .bar-button-default.bar-button-ios-default, .bar-button-clear-ios-default {
    color: #dbe0e4;
  }

  .toolbar-title-ios, .toolbar-title-md {
    color: #dbe0e4;
  }

  .toolbar-title img {
    padding: 10px;
  }

  ion-content {
    --background: #ebedf0;
    ion-list {
      background: #ebedf0;
    }
  }

  .content-md, .content-ios {
    background-color: #ebedf0;
  }

  ion-header .toolbar-ios, ion-header .toolbar-md {
    height: 50px;
    min-height: 100%;
    display: flex;
  }

  .smallhead .toolbar-ios, .smallhead .toolbar-md {
    min-height: 44px;
  }

  .smallhead .toolbar-ios ion-title, .smallhead .toolbar-md ion-title {
    padding-top: 4px;
  }

  .toolbar-ios ion-title, .toolbar-md ion-title {
    text-align: left;
  }

  .bar-button-dark-ios, .bar-button-default.bar-button-ios-dark, .bar-button-clear-ios-dark {
    color: var(--ion-color-background);
  }

  .bar-button-light-ios, .bar-button-default.bar-button-ios-light, .bar-button-clear-ios-light {
    color: #dbe0e4;
  }

  ion-header .bar-button-default-md, ion-header .bar-button-clear-md-default, ion-header .bar-button-md-default, ion-header .bar-buttons-md {
    background: #008acf;
    height: 100%;
  }

  ion-header .bar-button-default-ios, ion-header .bar-button-clear-ios-default, ion-header .bar-button-ios-default, ion-header .bar-buttons-ios {
    background: #008acf;
    height: 100%;
  }

  ion-header .bar-button-default-md .danger, ion-header .bar-button-clear-md-default .danger, ion-header .bar-button-md-default .danger, ion-header .bar-buttons-md .danger {
    background: #a30410;
    height: 100%;
  }

  ion-header .bar-button-default-ios .danger, ion-header .bar-button-clear-ios-default .danger, ion-header .bar-button-ios-default .danger, ion-header .bar-buttons-ios .danger {
    background: #a30410;
    height: 100%;
  }

  ion-header .bar-button-default-md .green, ion-header .bar-button-clear-md-default .green, ion-header .bar-button-md-default .green, ion-header .bar-buttons-md .green {
    background: #009245;
    height: 100%;
  }

  ion-header .bar-button-default-ios .green, ion-header .bar-button-clear-ios-default .green, ion-header .bar-button-ios-default .green, ion-header .bar-buttons-ios .green {
    background: #009245;
    height: 100%;
  }

  .metainfo {
    background: var(--ion-color-background);
    padding: 10px;
  }

  .vertical-align-content > * {
    display: flex !important;
    align-content: center !important;
    align-items: center !important;
  }

  .folder .item-inner {
    padding-right: 0px !important;
  }

  .folder {
    height: 280px;
    background: #9BA7AF;
    border-top: 10px solid #3d4a4e !important;
    color: #3d4a4e;
    text-transform: uppercase;
  }

  .bluebg .folder {
    background: #9BA7AF;
    color: #3d4a4e;
    border-top: 10px solid #3d4a4e !important;
    -webkit-box-shadow: 0px 0px 12px 2px rgba(179, 179, 179, 0.72);
    -moz-box-shadow: 0px 0px 12px 2px rgba(179, 179, 179, 0.72);
    box-shadow: 0px 0px 12px 2px rgba(179, 179, 179, 0.72);
    text-transform: uppercase;

  }

  ion-menu .content-md, ion-menu .content-ios {
    background-color: #7d8c94;
  }

  ion-menu .toolbar-background-md, ion-menu .toolbar-background-ios {
    background: #344045 !important;
  }

  ion-menu .menu-inner .item-md, ion-menu .menu-inner .item-ios {
    background-color: #4e5c62;
    color: #b5bfc7;
    text-transform: uppercase;
    margin: 10px 0px;
  }

  preview .canvas-container {
    margin: 10px 0px;
  }
  canvas-board .canvas-container {
    position: fixed !important;
  }
  canvas-board ion-toolbar{
    position: fixed;
    bottom: 0px;
  }

  ion-menu .menu-inner .item-md, ion-menu .menu-inner .item-ios {
    line-height: 2.2;
    border-left: 12px solid #3d4a4e;
  }

  ion-menu .menu-inner .list-md .item-block .item-inner, ion-menu .menu-inner .list-iosa .item-block .item-inner {
    border-bottom: 0px solid #dbe0e4;
    border-bottom-width: 0px;
    border-bottom-style: solid;
    border-bottom-color: #dbe0e4;
  }

  .variobend-action-sheet {

    /* not nice
    .action-sheet-group {
      background: #dbe0e3;
    }*/
    .action-sheet-title {
      background: #3d4a50;
      color: #dbe0e4;
    }
    /* Background not working
    .variobend-action-sheet-cancel {
      --button-color: #fff;
      --button-background: #a30410;
    }*/
  }

  .folder h1 {
    color: #eaedf0;
  }

  .folder h2 {
    text-transform: uppercase;
    font-weight: 500;
    color: #eaedf0;
  }

  .item-md.item-block .item-inner {
    border-bottom: 0px solid #dedede;
    box-shadow: none !important;
  }

  .button-inner span {
    font-weight: 500;
    padding-left: 5px;
  }

  .menu-inner .item-md, .menu-inner .item-ios {
    background-color: transparent;
    color: var(--ion-color-background);
  }

  ion-menu .toolbar-background-md, ion-menu .toolbar-background-ios {
    background: #008acf;
  }

  .menu-inner .list-md .item-block .item-inner {
    border-bottom: 1px solid #dbe0e4;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #dbe0e4;
  }

  .bar-button-default-md:hover:not(.disable-hover), .bar-button-clear-md-default:hover:not(.disable-hover), .bar-button-md-default:hover:not(.disable-hover) {
    color: #fff;
  }

  .toolbar-background-md, .toolbar-background-ios {
    border-color: #b2b2b2;
    background: #3d4a50;
    color: #dbe0e4;
  }

  .iconaktiv {
    ion-icon {
      color: #3ac774 !important;
    }
  }

  .pdf-modal .modal-wrapper {
    height: 100%;
    width: 100%;
  }

  ion-modal .smallhead .toolbar-ios, ion-modal .smallhead .toolbar-md {
    height: 44px;
  }

  ion-modal .toolbar-background-md, ion-modal .toolbar-background-ios {
    background: #3d4a50;
    color: #ffffff;
  }

  ion-modal .content-md, ion-modal .content-ios {
    color: #000;
    background-color: #b4bec5;
  }

  .label-md-primary, .item-input .label-md-primary, .item-select .label-md-primary, .item-datetime .label-md-primary {
    color: #3d4a50;
  }

  .label-ios-primary, .item-input .label-ios-primary, .item-select .label-ios-primary, .item-datetime .label-ios-primary {
    color: #3d4a50;
  }

  ion-header .bar-buttons {
    background: #008acf !important;
    margin: 0 0rem;
  }

  ion-header {
    border-bottom: 4px solid #fff;
  }

  ion-menu .menu-inner .item-ios {
    border-bottom: 0px !important;
    border-top: 0px !important;
  }

  .missing {
    border: 2px solid red;
  }

  ion-menu .menu-inner .item-ios .item-inner {
    border-bottom: 0px !important;
    border-top: 0px !important;
  }

  .tooltip-dummy {
    visibility: hidden;
  }

  .tooltip {
    --backdrop-opacity: 0.4;
  }

  .tooltip .popover-content {
    // --min-width: 50px;
    --min-height: 50px;
    --background: #ebedf0;
    padding: 10px;
    text-align: center;
    justify-content: center;
    --overflow: hidden;
  }
  .tooltip ion-content {
    --background: transparent;
    --overflow: hidden;
  }

  .tooltip ion-backdrop{
    background: var(--ion-color-dark);
  }

  .variobendDialog {
    button.primary {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-light);
      height: 30px;
      margin-bottom: 5px;
      border-radius: 2px;
      font-weight: 700;
    }
    button.danger {
      background-color: var(--ion-color-danger);
      color: var(--ion-color-light);
      height: 30px;
      margin-bottom: 5px;
      border-radius: 2px;
      font-weight: 700;
    }
  }

}
